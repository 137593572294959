.top-section {
    display: flex;
    padding: 16px 64px 0 64px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.logoutBtn {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.logoutBtn:hover {
    background-color: #da190b;
}
