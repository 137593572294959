.upperContainer {
    display: flex;
    gap: 20px;
    padding: 0 64px;
}

.heading {
    align-items: center;
    justify-content: center;
}

.userDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchInp {
    justify-content: right;
    margin-bottom: 20px;
}

.searchInp input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 300px;
    max-width: 100%;
    box-sizing: border-box;
}

.searchInp input[type="text"]::placeholder {
    color: #999;
}

.subjectTime {
    display: flex;
    align-items: center;
    margin-top: 8px;
    justify-content: space-between;
}

.selectContainer {
    margin-top: 16px;
    display: flex;
}

.selectContainer {
    margin-top: 16px;
    display: flex;
}

.selectContainer label {
    font-weight: bold;
    padding: 10px;
    font-size: large;
}

.selectContainer select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.cardContent {
    margin-top: 16px;
    padding: 0 115px;
}

/* Custom dropdown arrow */
.selectContainer select::-ms-expand {
    display: none;
}

.selectContainer select::after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #555;
}

.selectContainer select:hover {
    border-color: #888;
}

.selectContainer select:focus {
    outline: none;
    border-color: #555;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.heading {
    display: flex;
    justify-content: space-between;
}

.view-dropdown {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.view-dropdown label {
    margin-right: 10px;
    font-weight: bold;
}

.view-dropdown select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.userCard {
    padding: 0px 32px 32px 32px;
    border: black 1px solid;
}

.filterContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.filterContainer label {
    margin-right: 10px;
    font-weight: bold;
}

.filterContainer select {
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}
