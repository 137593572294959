.upperContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filterContainer {
    display: flex;
    align-items: center;
}

.filterContainer label {
    margin-right: 10px;
}

.adminTable {
    padding: 32px;
    width: 100%;
}

.searchInp input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 200px;
}

.tableBody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.tableBody tr:hover {
    background-color: #e9e9e9;
}